// extracted by mini-css-extract-plugin
export var ArtistDescription = "Rammellzee-module--ArtistDescription--x+9iI";
export var ArtistInfos = "Rammellzee-module--ArtistInfos--dnzjk";
export var ButtonWrapper = "Rammellzee-module--ButtonWrapper --EWZQa";
export var CardWrapper = "Rammellzee-module--CardWrapper--mVnDG";
export var CarrouselWrapper2 = "Rammellzee-module--CarrouselWrapper2--WvHdp";
export var Citations = "Rammellzee-module--Citations--L45Ut";
export var DescriptionWrapper = "Rammellzee-module--DescriptionWrapper--0wCt8";
export var ImageWrapper = "Rammellzee-module--ImageWrapper--dzZhK";
export var LinkWrapper = "Rammellzee-module--LinkWrapper--stDns";
export var MobileProtrait = "Rammellzee-module--MobileProtrait--u3rj8";
export var More = "Rammellzee-module--More--q2OU3";
export var MoreButton = "Rammellzee-module--MoreButton--ZhqUK";
export var NameWrapper = "Rammellzee-module--NameWrapper--4E+uB";
export var PdpWrapper = "Rammellzee-module--PdpWrapper--03YnT";
export var PhotosWrapper = "Rammellzee-module--PhotosWrapper--mwkBF";
export var ProfilWrapper = "Rammellzee-module--ProfilWrapper--mYHkc";
export var TitleWrapper = "Rammellzee-module--TitleWrapper--yrxdD";
export var Wrapper = "Rammellzee-module--Wrapper--qYSDR";